export const state = () => ({
  current_terms_version: 1, // in order to force users to show the terms dialog again we have to numeric increase this version like 1,2,3,4
  notifications: {
    proposals:0,
    quality:0,
    reviews_ready:0,
    user_notifications: 0,
    comment_tasks:0,
    assigned_proposals: 0,
  },
  settings: {
    has_terms_accepted: null, // this will recieve the current accept terms number, like 1 ... oder 0 if no terms accepted at all
    show_intro_overlay: false,
    show_newsletter_overlay: false,
    app_language: 'de',
    is_beta_optin: false
  },
  notificationsCount: 0,
  permissions: [],
  managerRole: false,
  ownerRole: false,
  can: {
    createIntegrationPlatform: false,
    updateIntegrationPlatform: false,
    updatePool: false,
    deletePool: false,
    proposals: false,
    quality: false,
    review: false,
    createTeammember: false,
    socialmedia: false,
    payment: false,
    filesmanagervirtualfolders: false,
    schemaUpdate: false,
  },
  schemas: [],
  menu: [],
  menu_org_settings: [],
  platforms: [], // menu platforms to filter content
  buttons: {content:[],admin:[],settings:[], content_integrations:[]},
  status: {cluster_version: 'loading'},
  pins: [],
  socialmediaPublish:{image_id:0,message:'',links:[]},
  isLoaded : false,
});

export const mutations = {
  SET_BETA_OPTING(context, flag) {
    context.settings.is_beta_optin = flag
  },
  SET_SOCIALMEDIA_PUBLISH(context, data) {
    context.socialmediaPublish = data
  },
  SET_NOTIFICATIONS(context, data) {
    context.notifications = data;
  },
  SET_PERMISSIONS(context, data) {
    const perms = data.permissions
    context.permissions = perms
    context.can.createIntegrationPlatform = perms.includes('integrationplatform.create') || data.ownerRole
    context.can.updateIntegrationPlatform = perms.includes('integrationplatform.update') || data.ownerRole
    context.can.deleteIntegrationPlatform = perms.includes('integrationplatform.delete') || data.ownerRole
    context.can.updatePool = perms.includes('pool.update') || data.ownerRole
    context.can.deletePool = perms.includes('pool.delete') || data.ownerRole
    context.can.quality = perms.includes('quality')
    context.can.proposals = perms.includes('proposals')
    context.can.review = perms.includes('review')
    context.can.createTeammember = perms.includes('teammember.create')
    context.can.updateTeammember = perms.includes('teammember.update')
    context.can.updateRoles = perms.includes('role.update')
    context.can.socialmedia = perms.includes('socialmedia')
    context.can.payment = perms.includes('payment')
    context.can.stats = perms.includes('stats')
    context.can.filesmanagervirtualfolders = perms.includes('filesmanagervirtualfolders') || data.ownerRole
    context.can.schemaUpdate = perms.includes('schema.update')
  },
  SET_MENU(context, data) {
    context.menu = data
  },
  SET_ORG_SETTINGS_MENU(context, data) {
    context.menu_org_settings = data
  },
  SET_BUTTONS(context, data) {
    context.buttons = data
  },
  SET_PLATFORMS(context, data) {
    context.platforms = data
  },
  SET_SCHEMAS(context, data) {
    context.schemas = data
  },
  SET_MANAGER_ROLE(context, data) {
    context.managerRole = data
  },
  SET_OWNER_ROLE(context, data) {
    context.ownerRole = data
  },
  SET_SETTINGS(context, data) {
    context.settings = data
  },
  SET_PINS(context, data) {
    context.pins = data.map(item => {
      item.loading = false
      return item
    })
  },
  SET_PIN_LOADING(context, index) {
    context.pins[index].loading = true
  },
  REMOVE_PIN(context, index) {
    context.pins.splice(index, 1)
  },
  SET_STATUS(context, status) {
    context.status = status
  },
  SET_IS_LOADED(context) {
    context.isLoaded = true
  }
}

export const getters = {

  notificationsTotalCount: state => {
    const p = state.notifications

    let count = 0;

    if (state.can.proposals) {
      count += parseInt(p.proposals)
    }

    if (state.can.quality) {
      count += parseInt(p.quality)
    }

    if (state.can.review) {
      count += parseInt(p.reviews_ready)
    }

    return count + parseInt(p.user_notifications) + parseInt(p.comment_tasks) + parseInt(p.assigned_proposals)
  },
  organisationMenu: state => {
    return state.menu_org_settings
  },
  contenteditorMenu: state => {
    return state.menu.find(item => item.identifier == 'contenteditor')
  },
  appLanguage: state => {
    return state.settings.app_language
  },
  clusterVersion: state => {
    return state.status.cluster_version
  },
  isBetaOptin: state => {
    return state.settings.is_beta_optin || false
  }
}

export const actions = {
  async fetch (context) {
    await this.$axios.get('me', { progress: false }).then(response => {
      context.commit('SET_SCHEMAS', response.data.schemas)
      context.commit('SET_MANAGER_ROLE', response.data.managerRole)
      context.commit('SET_OWNER_ROLE', response.data.ownerRole)
      context.commit('SET_NOTIFICATIONS', response.data.notifications);
      context.commit('SET_SETTINGS', response.data.settings)
      context.commit('SET_PINS', response.data.pins)
      context.commit('SET_BETA_OPTING', response.data.settings?.is_beta_optin || false)
      context.commit('SET_PERMISSIONS', response.data)
      context.commit('SET_IS_LOADED')
    }, error => {
      throw new Error('invalid auth');
    })
  },
  async menu (context) {
    try {
      const response = await this.$axios('organisation/menu')
      context.commit('SET_MENU', response.data.menu)
      context.commit('SET_ORG_SETTINGS_MENU', response.data.menu_org_settings)
      context.commit('SET_BUTTONS', response.data.buttons)
      context.commit('SET_PLATFORMS', response.data.platforms)
      context.commit('SET_STATUS', response.data.status)
    } catch (error) {
      context.commit('SET_MENU', [])
      context.commit('SET_ORG_SETTINGS_MENU', [])
      context.commit('SET_PLATFORMS', [])
    }
  },
  async pin (context, item) {
    await this.$axios.post('entity-pins', {entity_id: item.id, entity_type:item.type})
    await context.dispatch('fetch')
  },
  async removePin(context, pin) {
    context.commit('SET_PIN_LOADING', pin.index)
    await this.$axios.delete('entity-pins/' + pin.id)
    context.commit('REMOVE_PIN', pin.index)
  }
}
