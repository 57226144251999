
export default {
  props: {
    dataCy: {
      type: String,
      default: 'closeButton'
    },
    title: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    colored: {
      type: Boolean,
      default: false
    },
    color: {
      type: [String, Boolean],
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDataChangeAware: {
      type: Boolean,
      default: false,
    },
    isDataChangeValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    themedColor() {

      if (this.isDataChangeAware && this.isDataChangeValue) {
        return this.$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-2'
      }

      if (!this.colored) {
        return this.$vuetify.theme.dark ? 'grey darken-4' : ''
      }

      if (this.color === false) {
        return this.$vuetify.theme.dark ? 'grey darken-3': 'grey lighten-3'
      }

      return this.color
    }
  }
}
