
// https://v2.nuxt.com/docs/concepts/views/#error-page
// this is not a "real layout" but nuxtjs handles errors in thes layouts/error.vue file
export default {
  layout: 'containerlarge',
  props: {
    error: {
      type: [Object, String, Number],
      default: () => {}
    }
  },
  fetch() {
    this.$store.commit('containerLayout/setTitle', 'Fehler');
    if (this.error.statusCode !== 404) {
      this.$sentry.captureMessage(this.stringableErrorMessage, {
        extra: {
          responseBody: this.stringableResponseBody,
          error: this.error
        }
      })
    }
  },
  computed: {
    message() {
      return this.error.statusCode === 404
        ? 'Die von dir aufgerufene Seite existiert nicht. Überprüfe bitte die URL oder kehre zur Startseite zurück.'
        : `Ein Anwendungsfehler ist aufgetreten und Flyo wurde umgehend darüber informiert. Wir entschuldigen uns für die Unannehmlichkeiten. Wenn das Problem weiterhin besteht, kontaktiere bitte support@flyo.cloud.`
    },
    stringableErrorMessage() {
      return this.error?.message || '';
    },
    stringableResponseBody() {
      const responseData = this.error.response?.data;

      if (this.error.statusCode === 422 && Array.isArray(responseData)) {
        return responseData.map(item => `${item.message}`).join('\n');
      }

      if (responseData) {
        if (typeof responseData === 'string') {
          return responseData;
        }

        if (typeof responseData === 'object') {
          return JSON.stringify(responseData, null, 2);
        }
      }

      return ''
    }
  },
  methods: {
    reload() {
      if (this.error.statusCode === 404) {
        this.$router.push('/')
      } else {
        this.$router.go()
      }
    }
  }
}
