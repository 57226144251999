import { toCompareableString } from '~/helpers/compare.js'

export const state = () => ({
  _isDataChanged: false,
  _initialState: null,
  _compareState: null,
});

/**
 * $store.getters['unsavedChanges/isDataChanged']
 *
 * or as vuex getter:
 *
 * import { mapGetters } from 'vuex';
 * computed: {
 *   ...mapGetters({
 *     isDataChanged: 'unsavedChanges/isDataChanged',
 *   })
 * },
 */
export const getters = {
  isDataChanged: state => state._isDataChanged,
}

/**
 * $store.commit('unsavedChanges/initialState', inputData)
 * $store.commit('unsavedChanges/compareState', compareData)
 */
export const mutations = {
  initialState(state, data) {
    state._initialState = toCompareableString(data);
    state._isDataChanged = false;
    state._compareState = null;
  },
  compareState(state, data) {
    state._compareState = toCompareableString(data);
    state._isDataChanged = state._initialState !== state._compareState;
  },
}
