
import DenseContainer from '../components/screens/DenseContainer.vue'
export default {
  components: { DenseContainer },
  data: () => ({
    news: false,
    isNewsVisible: false,
  }),
  async fetch() {

    if (this.$vuetify.breakpoint.mdAndUp && this.$nuxt.context.env.release !== 'localhost') { // && this.$nuxt.context.env.release !== 'localhost'
      const response = await this.$axios.$get('https://api.flyo.cloud/integration/api/1618/m-CJDwibeyaZoB_4qZRbPaq9-0nnWO-z9w8x4edHOTnHDRgctGmtnfjHCiW9Njp1MlIwGLRhj-ZSJn4m5AeKCuKetqVI')
      if (response.length > 0) {
        this.news = response[0]
        setTimeout(() => {
          this.isNewsVisible = true
        }, 2500)
      }
    }
  },
  computed: {
    title() {
      return this.$store.getters['containerLayout/getTitle']
    },
  },
}
