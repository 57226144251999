
import DenseContainer from "../components/screens/DenseContainer.vue"

export default{
  components: { DenseContainer },
  data:() => ({
    response: false,
    isActive: false,
    isLoading: true,
  }),
  async fetch() {
    this.isLoading = true
    // if you are here, whatever happens we close the socket
    await this.$store.dispatch('hub/websocketUsersClose')
    try {
      // todo:
      // wenn gar keine challenge existiert oder leer
      // können wir direkt false zurück geben ohne den "/me" call ... .vielliecht? aber auch nicht shclimmt?
      const user = await this.$store.dispatch('authUserChallenge/getAndSetUser')
      this.response = user
      this.isActive = true
    } catch (error) {
      this.isActive = false
      // hey hier müssen wir alles killen jetzt
      // alle traffic landet hier: von guest und von auth middelware
      this.$store.commit('authUserChallenge/destroy')
      this.$store.commit('authStorage/destroy')
    }

    this.isLoading = false
  },
  computed: {
    user() {
      return this.response || false
    },
    title() {
      return this.$store.getters['containerLayout/getTitle']
    }
  }
}
