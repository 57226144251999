export default (context) => {
  if (process.client) {
    let darkMode = context.store.getters['authUserChallenge/getDarkMode']

    if (darkMode === null) {
      darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
      context.store.commit('authUserChallenge/setDarkMode', darkMode)
    }

    // Access the Vuetify instance and set the dark mode
    if (darkMode !== null && context.app && context.app.vuetify && context.app.vuetify.framework) {
      context.app.vuetify.framework.theme.dark = darkMode
    }
  }
}
