import en from 'vuetify/es5/locale/en'

export default {
  ...en,
  yes: "Yes",
  no: "No",
  close: "Close",
  delete: "Delete",
  add: "Add",
  insert: "Insert",
  change: "Change",
  use: "Use",
  show: "Show",
  all: "All",
  addPin: "Pin Entry",
  newEntry: "New Entry",
  viewEntry: "View Entry",
  curated: "Curated",
  rulebased: "Rule-based",
  organisation: "Organization",
  unsavedChanges: "Unsaved Changes",
  copyToClipboardSucces: "Copied to Clipboard",
  identifier: 'Identifier',
  label: 'Label',
  icon: 'Icon',
  image: 'Image',
  youtube: 'YouTube',
  startTipping: 'Start Typing',
  settings: 'Settings',
  integrations: {
    areYouSureToLeave: "Seite wirklich verlassen? Ungespeicherte Änderungen gehen verloren."
  },
  layouts: {
    orgswitcher: {
      title: "Switch Organization",
      subtitle: "Select an organization to edit its content."
    },
    pinneditems: "Pinned Items",
    pinremoved: "Pin Removed",
    pinadded: "Entry Pinned",
    entitySuccessQueue: {
      errorTitle: "Timeout",
      errorText: "Processing the changes is taking longer than usual. The changes have been saved and will be processed when sufficient resources are available.",
      saving: "Saving..."
    },
    help: {
      title: "Support",
      inputPlaceholder: "Search Help",
      noItems: "No posts found."
    },
    queue: {
      oneMoment: "One moment please...",
      waitingText: "We are collecting all the necessary information and saving the data."
    },
    reventItems: {
      title: "Work History",
      empty: "Pretty empty here.",
      emptyInfo: "Once you open content, content pools, or integrations, they will appear in the work history. This allows you to quickly switch between different items."
    },
    search: {
      title: "Search",
      label: "Enter search term...",
      noDataText: "Enter a search term with at least 3 letters.",
      minSearchLength: "Search term must contain at least 3 characters.",
      noResults: "No entries found for the term {0}"
    },
    terms: {
      title: "Terms & Privacy Policy",
      subtitle: "Before you can get started, you must accept our Terms & Privacy Policy:",
      acceptPrivacy: "I have read the privacy policy",
      acceptTerms: "I accept the terms and conditions"
    },
    welcomeHelpOverlay: {
      title: "Welcome to Flyo",
      subtitle: "Useful information to get you started right away.",
      doNotShowAgain: "Do not show again"
    },
    notifications: {
      title: "Notifications",
      crosschecks: "Cross Checks",
      quality: "Data Quality",
      proposals: "Change Proposals",
      all_proposals: "All Change Proposals",
      personal: "Personal",
      approve: "Approve"
    }
  },
  footer: {
    terms: '<a href="/terms">Terms and Conditions</a> and <a href="/imprint">Imprint</a>'
  },
  attributes: {
    addressZip: 'ZIP Code',
    addressCity: 'City',
    addressStreet: 'Street',
    addressStreetNr: 'Street Number',
    addressLat: 'Coordinates (Latitude, Lat)',
    addressLng: 'Coordinates (Longitude, Long)',
    addressToggleAutoCords: 'Automatic Coordinates',
    addressToggleCustomCords: 'Manage Coordinates Manually',
    typeConfigCustomElementSlugLabel: 'Element Name',
    typeConfigCustomElementSlugHint: 'This name is used in programming to access these elements.',
    typeConfigCustomElementHasContentLabel: 'Recursive Content Input',
    typeConfigCustomElementHasContentHint: 'When the checkbox is activated, a special area is unlocked in the custom element, into which further WYSIWYG contents can be inserted recursively. These contents can then be accessed via the variable {$content} or within the json using getContent(). The area supports the insertion of all standard WYSIWYG elements and maintains the hierarchical structure of the inserted content.',
    typeConfigCustomElementHtmlLabel: 'HTML Output',
    typeConfigCustomElementHtmlHint: 'To use the element in HTML-compatible integrations, it can be defined through valid HTML syntax. All properties within the double curly braces {{ }} are available. Assuming there are properties named text and image, an exemplary HTML code might look like this: <p><b>{{ text }}</b><img src="{{ image }}" border="0" alt="{{ text }}" /></p>`',
    typeConfigCustomElementLabelLabel: 'Display Name in the Editor',
    typeConfigCustomElementAttributesHint: 'Attributes are properties assigned to an HTML element that define its characteristics.',
    typeConfigCustomElementAttributesSubtitle: 'Attributes',
    typeConfigCustomElementAttributesEmtpy: 'No properties have been defined yet.',
    typeConfigCustomElementAttributesFieldtype: 'Field Type *',
    typeConfigCustomElementAttributesAdd: 'Add Properties',
    typeConfigCustomElementAdd: 'Add Custom Element',
    typeMultipleinputAdd: 'Add Field',
    colorStoreNewColor: 'Store New Color',
    jsonInfoTitle: 'Dropdown Selection',
    jsonInfoText: 'This list is displayed to the user as a dropdown selection. The value in the first field corresponds to the value in the database. The value on the right corresponds to the value shown to the user (Label).',
    latLngLatLabel: 'Latitude/Lat',
    latLngLngLabel: 'Longitude/Lng',
    linkTargetSameWindow: 'Same Window',
    linkTargetNewWindow: 'New Window',
    linkTypeFile: 'File',
    linkTypeWebsite: 'Website',
    linkTypeEmail: 'Email',
    linkTypePhone: 'Phone',
    linkNitroPage: 'CMS Page',
    openinghoursClosed: 'Closed',
    openinghoursAddLunchBreak: 'Insert Lunch Break',
    openinghoursNoEntires: 'No Opening Hours',
    openinghoursAdd: 'Add Opening Hours',
    openinghoursMo: 'Monday',
    openinghoursDi: 'Tuesday',
    openinghoursMi: 'Wednesday',
    openinghoursDo: 'Thursday',
    openinghoursFr: 'Friday',
    openinghoursSa: 'Saturday',
    openinghoursSo: 'Sunday',
    orderingAddField: 'Add Field',
    orderingAscHint: 'Corresponds to ascending from A-Z or for numbers 1, 2, 3',
    orderingDescHint: 'Corresponds to descending from Z-A or for numbers 3, 2, 1',
    orderingDateInfo: 'A field with date or time information is converted into a numeric value (Unix Timestamp). To sort the farthest entry first, use',
    orderingDateInfo2: '. To display the newest entry first, use',
    orderingDateInfo3: '.',
    poolTypesUsers: 'Users',
    poolTypesFiles: 'Files',
    wysiwygSetLink: 'Set Link',
    wysiwygUpdateLink: 'Link Update',
    wysiwygSetYoutube: 'YouTube Video',
  }
}
