export function typeCastToCompareable(obj) {
  if (obj === null) {
    return ""; // Convert null to an empty string
  } else if (typeof obj === "boolean") {
    return obj ? "1" : ""; // Convert true to "1" and false to ""
  } else if (typeof obj === "number") {
    return obj === 0 ? "" : obj.toString(); // Convert 0 to "" and other numbers to strings
  } else if (Array.isArray(obj)) {
    // If it's an empty array, treat it as an empty "thing" (e.g., an empty string)
    if (obj.length === 0) {
      return "";
    }
    // Otherwise, map each element recursively
    return obj.map(element => typeCastToCompareable(element));
  } else if (typeof obj === "object") {
    // If it's an empty object, treat it as an empty "thing" (e.g., an empty string)
    if (Object.keys(obj).length === 0) {
      return "";
    }

    // Handle special case for wysiwyg
    const keys = Object.keys(obj);
    if ((keys.length === 2 && keys.includes("html") && keys.includes("json")) || (keys.length === 1 && keys.includes("json"))) {
      return typeCastToCompareable(obj.json); // Use only the "json" content
    }

    // Otherwise, recursively apply type casting to each property
    const result = {};
    for (const key in obj) {
      result[key] = typeCastToCompareable(obj[key]);
    }
    return result;
  } else {
    return obj; // Leave strings and other types as they are
  }
}

export function toCompareableString(obj) {
  // Deep copy the input to avoid modifying the original
  const copy = structuredClone ? structuredClone(obj) : JSON.parse(JSON.stringify(obj));
  return JSON.stringify(typeCastToCompareable(copy));
}
