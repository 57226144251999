
import DenseContainer from '../components/screens/DenseContainer.vue'
export default {
  components: { DenseContainer },
  computed: {
    title() {
      return this.$store.getters['containerLayout/getTitle']
    }
  },
}
