import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e6becbd4 = () => interopDefault(import('../pages/approvals.vue' /* webpackChunkName: "pages/approvals" */))
const _984a8fce = () => interopDefault(import('../pages/approvals/index.vue' /* webpackChunkName: "pages/approvals/index" */))
const _59b90a2f = () => interopDefault(import('../pages/approvals/notifications.vue' /* webpackChunkName: "pages/approvals/notifications" */))
const _465d0408 = () => interopDefault(import('../pages/approvals/proposals.vue' /* webpackChunkName: "pages/approvals/proposals" */))
const _ba02cf2c = () => interopDefault(import('../pages/approvals/ready.vue' /* webpackChunkName: "pages/approvals/ready" */))
const _2bd1be42 = () => interopDefault(import('../pages/content/index.vue' /* webpackChunkName: "pages/content/index" */))
const _3c6b03c3 = () => interopDefault(import('../pages/filesmanager/index.vue' /* webpackChunkName: "pages/filesmanager/index" */))
const _3466728f = () => interopDefault(import('../pages/imprint.vue' /* webpackChunkName: "pages/imprint" */))
const _42987702 = () => interopDefault(import('../pages/me.vue' /* webpackChunkName: "pages/me" */))
const _73bc3405 = () => interopDefault(import('../pages/me/index.vue' /* webpackChunkName: "pages/me/index" */))
const _1806e772 = () => interopDefault(import('../pages/me/data.vue' /* webpackChunkName: "pages/me/data" */))
const _59cd4e5d = () => interopDefault(import('../pages/me/security.vue' /* webpackChunkName: "pages/me/security" */))
const _60c7e8f9 = () => interopDefault(import('../pages/notfound.vue' /* webpackChunkName: "pages/notfound" */))
const _2cb262c8 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _78227e47 = () => interopDefault(import('../pages/organisation/index.vue' /* webpackChunkName: "pages/organisation/index" */))
const _405c77e9 = () => interopDefault(import('../pages/platforms/index.vue' /* webpackChunkName: "pages/platforms/index" */))
const _75a67ba0 = () => interopDefault(import('../pages/pools/index.vue' /* webpackChunkName: "pages/pools/index" */))
const _39606bba = () => interopDefault(import('../pages/pools/index/index.vue' /* webpackChunkName: "pages/pools/index/index" */))
const _d9000d6a = () => interopDefault(import('../pages/pools/index/_id.vue' /* webpackChunkName: "pages/pools/index/_id" */))
const _3b211b8e = () => interopDefault(import('../pages/pools/index/_id/index.vue' /* webpackChunkName: "pages/pools/index/_id/index" */))
const _0b91f7a8 = () => interopDefault(import('../pages/pools/index/_id/export.vue' /* webpackChunkName: "pages/pools/index/_id/export" */))
const _2512eb86 = () => interopDefault(import('../pages/pools/index/_id/update.vue' /* webpackChunkName: "pages/pools/index/_id/update" */))
const _6014c9e0 = () => interopDefault(import('../pages/socialmedia/index.vue' /* webpackChunkName: "pages/socialmedia/index" */))
const _3ae3f8b6 = () => interopDefault(import('../pages/stats.vue' /* webpackChunkName: "pages/stats" */))
const _1ef07a68 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _072b59e2 = () => interopDefault(import('../pages/stats/content.vue' /* webpackChunkName: "pages/stats/content" */))
const _14e107ce = () => interopDefault(import('../pages/stats/highlights.vue' /* webpackChunkName: "pages/stats/highlights" */))
const _de2ac30e = () => interopDefault(import('../pages/stats/integrations.vue' /* webpackChunkName: "pages/stats/integrations" */))
const _1bff5618 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _06e291a1 = () => interopDefault(import('../pages/switch/index.vue' /* webpackChunkName: "pages/switch/index" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _776c6720 = () => interopDefault(import('../pages/auth/2fa.vue' /* webpackChunkName: "pages/auth/2fa" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9a19d152 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _07668533 = () => interopDefault(import('../pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _5a9ff091 = () => interopDefault(import('../pages/auth/restore.vue' /* webpackChunkName: "pages/auth/restore" */))
const _b9aaaa36 = () => interopDefault(import('../pages/content/import.vue' /* webpackChunkName: "pages/content/import" */))
const _a9d1eae0 = () => interopDefault(import('../pages/oauth/webflow.vue' /* webpackChunkName: "pages/oauth/webflow" */))
const _f35c7dac = () => interopDefault(import('../pages/organisation/activity.vue' /* webpackChunkName: "pages/organisation/activity" */))
const _acdc5b78 = () => interopDefault(import('../pages/organisation/admin.vue' /* webpackChunkName: "pages/organisation/admin" */))
const _766095c7 = () => interopDefault(import('../pages/organisation/admin/index.vue' /* webpackChunkName: "pages/organisation/admin/index" */))
const _8b782b76 = () => interopDefault(import('../pages/organisation/admin/data.vue' /* webpackChunkName: "pages/organisation/admin/data" */))
const _67278210 = () => interopDefault(import('../pages/organisation/admin/languages.vue' /* webpackChunkName: "pages/organisation/admin/languages" */))
const _66e5f3e5 = () => interopDefault(import('../pages/organisation/admin/notificationfilters.vue' /* webpackChunkName: "pages/organisation/admin/notificationfilters" */))
const _5dcd054a = () => interopDefault(import('../pages/organisation/admin/security.vue' /* webpackChunkName: "pages/organisation/admin/security" */))
const _1ffc204e = () => interopDefault(import('../pages/organisation/cleanup.vue' /* webpackChunkName: "pages/organisation/cleanup" */))
const _07876f9c = () => interopDefault(import('../pages/organisation/cleanup/index.vue' /* webpackChunkName: "pages/organisation/cleanup/index" */))
const _2fd82142 = () => interopDefault(import('../pages/organisation/cleanup/pool.vue' /* webpackChunkName: "pages/organisation/cleanup/pool" */))
const _0da3e47f = () => interopDefault(import('../pages/organisation/cleanup/rows.vue' /* webpackChunkName: "pages/organisation/cleanup/rows" */))
const _7e4c9702 = () => interopDefault(import('../pages/organisation/cleanup/trash.vue' /* webpackChunkName: "pages/organisation/cleanup/trash" */))
const _02ff9f8a = () => interopDefault(import('../pages/organisation/connections.vue' /* webpackChunkName: "pages/organisation/connections" */))
const _1e74d68d = () => interopDefault(import('../pages/organisation/connections/index.vue' /* webpackChunkName: "pages/organisation/connections/index" */))
const _76db8298 = () => interopDefault(import('../pages/organisation/connections/integrations.vue' /* webpackChunkName: "pages/organisation/connections/integrations" */))
const _9b7ffc72 = () => interopDefault(import('../pages/organisation/connections/openai.vue' /* webpackChunkName: "pages/organisation/connections/openai" */))
const _223a4342 = () => interopDefault(import('../pages/organisation/entities/index.vue' /* webpackChunkName: "pages/organisation/entities/index" */))
const _0404365d = () => interopDefault(import('../pages/organisation/focusboards/index.vue' /* webpackChunkName: "pages/organisation/focusboards/index" */))
const _e6e76b8a = () => interopDefault(import('../pages/organisation/payment.vue' /* webpackChunkName: "pages/organisation/payment" */))
const _04afa096 = () => interopDefault(import('../pages/organisation/roles/index.vue' /* webpackChunkName: "pages/organisation/roles/index" */))
const _7121cd40 = () => interopDefault(import('../pages/organisation/users/index.vue' /* webpackChunkName: "pages/organisation/users/index" */))
const _1a326016 = () => interopDefault(import('../pages/platforms/create.vue' /* webpackChunkName: "pages/platforms/create" */))
const _ddace256 = () => interopDefault(import('../pages/pool/create.vue' /* webpackChunkName: "pages/pool/create" */))
const _6b8e8644 = () => interopDefault(import('../pages/socialmedia/create.vue' /* webpackChunkName: "pages/socialmedia/create" */))
const _e8c090b4 = () => interopDefault(import('../pages/support/create.vue' /* webpackChunkName: "pages/support/create" */))
const _8fe1f5ba = () => interopDefault(import('../pages/content/users/create.vue' /* webpackChunkName: "pages/content/users/create" */))
const _152d383f = () => interopDefault(import('../pages/organisation/entities/create.vue' /* webpackChunkName: "pages/organisation/entities/create" */))
const _b7923ffe = () => interopDefault(import('../pages/organisation/focusboards/create.vue' /* webpackChunkName: "pages/organisation/focusboards/create" */))
const _41dbdcae = () => interopDefault(import('../pages/organisation/roles/create.vue' /* webpackChunkName: "pages/organisation/roles/create" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1b6a89e6 = () => interopDefault(import('../pages/content/schemas/_id.vue' /* webpackChunkName: "pages/content/schemas/_id" */))
const _530aea23 = () => interopDefault(import('../pages/content/users/_id.vue' /* webpackChunkName: "pages/content/users/_id" */))
const _24fa0a66 = () => interopDefault(import('../pages/content/users/_id/index.vue' /* webpackChunkName: "pages/content/users/_id/index" */))
const _6026d439 = () => interopDefault(import('../pages/content/users/_id/focusboards.vue' /* webpackChunkName: "pages/content/users/_id/focusboards" */))
const _75669a18 = () => interopDefault(import('../pages/content/users/_id/platforms.vue' /* webpackChunkName: "pages/content/users/_id/platforms" */))
const _276b7a2a = () => interopDefault(import('../pages/content/users/_id/pools.vue' /* webpackChunkName: "pages/content/users/_id/pools" */))
const _6a2e82d1 = () => interopDefault(import('../pages/content/users/_id/roles.vue' /* webpackChunkName: "pages/content/users/_id/roles" */))
const _3ebb7665 = () => interopDefault(import('../pages/content/users/_id/update.vue' /* webpackChunkName: "pages/content/users/_id/update" */))
const _97fa26f2 = () => interopDefault(import('../pages/organisation/entities/_id.vue' /* webpackChunkName: "pages/organisation/entities/_id" */))
const _6257b1ca = () => interopDefault(import('../pages/organisation/entities/_id/index.vue' /* webpackChunkName: "pages/organisation/entities/_id/index" */))
const _28bda1c4 = () => interopDefault(import('../pages/organisation/entities/_id/copilot.vue' /* webpackChunkName: "pages/organisation/entities/_id/copilot" */))
const _27e3c763 = () => interopDefault(import('../pages/organisation/entities/_id/delete.vue' /* webpackChunkName: "pages/organisation/entities/_id/delete" */))
const _3bb8ed1e = () => interopDefault(import('../pages/organisation/entities/_id/fields.vue' /* webpackChunkName: "pages/organisation/entities/_id/fields" */))
const _1060c1fc = () => interopDefault(import('../pages/organisation/entities/_id/form.vue' /* webpackChunkName: "pages/organisation/entities/_id/form" */))
const _40a4bab1 = () => interopDefault(import('../pages/organisation/entities/_id/interface.vue' /* webpackChunkName: "pages/organisation/entities/_id/interface" */))
const _38f62c05 = () => interopDefault(import('../pages/organisation/focusboards/_id.vue' /* webpackChunkName: "pages/organisation/focusboards/_id" */))
const _49789b5d = () => interopDefault(import('../pages/organisation/roles/_id.vue' /* webpackChunkName: "pages/organisation/roles/_id" */))
const _00c86820 = () => interopDefault(import('../pages/organisation/roles/_id/index.vue' /* webpackChunkName: "pages/organisation/roles/_id/index" */))
const _da952b5c = () => interopDefault(import('../pages/organisation/roles/_id/permissions.vue' /* webpackChunkName: "pages/organisation/roles/_id/permissions" */))
const _468e602a = () => interopDefault(import('../pages/organisation/roles/_id/update.vue' /* webpackChunkName: "pages/organisation/roles/_id/update" */))
const _46324256 = () => interopDefault(import('../pages/organisation/roles/_id/users.vue' /* webpackChunkName: "pages/organisation/roles/_id/users" */))
const _2d56f72a = () => interopDefault(import('../pages/schemas/rows/_id.vue' /* webpackChunkName: "pages/schemas/rows/_id" */))
const _fe2e62a4 = () => interopDefault(import('../pages/schemas/rows/_id/index.vue' /* webpackChunkName: "pages/schemas/rows/_id/index" */))
const _b74f793a = () => interopDefault(import('../pages/schemas/rows/_id/activity.vue' /* webpackChunkName: "pages/schemas/rows/_id/activity" */))
const _08fdf0b2 = () => interopDefault(import('../pages/schemas/rows/_id/connection.vue' /* webpackChunkName: "pages/schemas/rows/_id/connection" */))
const _63a105d3 = () => interopDefault(import('../pages/schemas/rows/_id/files.vue' /* webpackChunkName: "pages/schemas/rows/_id/files" */))
const _67905723 = () => interopDefault(import('../pages/schemas/rows/_id/permission.vue' /* webpackChunkName: "pages/schemas/rows/_id/permission" */))
const _324a11c6 = () => interopDefault(import('../pages/schemas/rows/_id/proposal.vue' /* webpackChunkName: "pages/schemas/rows/_id/proposal" */))
const _60a53b1d = () => interopDefault(import('../pages/schemas/rows/_id/update.vue' /* webpackChunkName: "pages/schemas/rows/_id/update" */))
const _2b296a14 = () => interopDefault(import('../pages/auth/register/_token/_id.vue' /* webpackChunkName: "pages/auth/register/_token/_id" */))
const _ddac4c1c = () => interopDefault(import('../pages/auth/reset/_token/_id.vue' /* webpackChunkName: "pages/auth/reset/_token/_id" */))
const _6f5d1aeb = () => interopDefault(import('../pages/filesmanager/_id.vue' /* webpackChunkName: "pages/filesmanager/_id" */))
const _2e20a2d6 = () => interopDefault(import('../pages/focusboards/_id.vue' /* webpackChunkName: "pages/focusboards/_id" */))
const _3d496091 = () => interopDefault(import('../pages/platforms/_id.vue' /* webpackChunkName: "pages/platforms/_id" */))
const _7a151358 = () => interopDefault(import('../pages/platforms/_id/index.vue' /* webpackChunkName: "pages/platforms/_id/index" */))
const _eb74a24e = () => interopDefault(import('../pages/platforms/_id/pools.vue' /* webpackChunkName: "pages/platforms/_id/pools" */))
const _602d8837 = () => interopDefault(import('../pages/platforms/_id/update.vue' /* webpackChunkName: "pages/platforms/_id/update" */))
const _8ceda3f2 = () => interopDefault(import('../pages/platforms/_id/integrations/_integration.vue' /* webpackChunkName: "pages/platforms/_id/integrations/_integration" */))
const _d8f2596c = () => interopDefault(import('../pages/platforms/_id/integrations/_integration/index.vue' /* webpackChunkName: "pages/platforms/_id/integrations/_integration/index" */))
const _1c98d5e3 = () => interopDefault(import('../pages/platforms/_id/integrations/_integration/delete.vue' /* webpackChunkName: "pages/platforms/_id/integrations/_integration/delete" */))
const _5ad70bcf = () => interopDefault(import('../pages/platforms/_id/integrations/_integration/pools.vue' /* webpackChunkName: "pages/platforms/_id/integrations/_integration/pools" */))
const _8af9735c = () => interopDefault(import('../pages/platforms/_id/integrations/_integration/tokens.vue' /* webpackChunkName: "pages/platforms/_id/integrations/_integration/tokens" */))
const _bc706bfe = () => interopDefault(import('../pages/platforms/_id/integrations/_integration/update.vue' /* webpackChunkName: "pages/platforms/_id/integrations/_integration/update" */))
const _bc49aaba = () => interopDefault(import('../pages/schemas/_id.vue' /* webpackChunkName: "pages/schemas/_id" */))
const _431bfe80 = () => interopDefault(import('../pages/support/_id.vue' /* webpackChunkName: "pages/support/_id" */))
const _690aef6e = () => interopDefault(import('../pages/switch/_id.vue' /* webpackChunkName: "pages/switch/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/approvals",
    component: _e6becbd4,
    children: [{
      path: "",
      component: _984a8fce,
      name: "approvals"
    }, {
      path: "notifications",
      component: _59b90a2f,
      name: "approvals-notifications"
    }, {
      path: "proposals",
      component: _465d0408,
      name: "approvals-proposals"
    }, {
      path: "ready",
      component: _ba02cf2c,
      name: "approvals-ready"
    }]
  }, {
    path: "/content",
    component: _2bd1be42,
    name: "content"
  }, {
    path: "/filesmanager",
    component: _3c6b03c3,
    name: "filesmanager"
  }, {
    path: "/imprint",
    component: _3466728f,
    name: "imprint"
  }, {
    path: "/me",
    component: _42987702,
    children: [{
      path: "",
      component: _73bc3405,
      name: "me"
    }, {
      path: "data",
      component: _1806e772,
      name: "me-data"
    }, {
      path: "security",
      component: _59cd4e5d,
      name: "me-security"
    }]
  }, {
    path: "/notfound",
    component: _60c7e8f9,
    name: "notfound"
  }, {
    path: "/onboarding",
    component: _2cb262c8,
    name: "onboarding"
  }, {
    path: "/organisation",
    component: _78227e47,
    name: "organisation"
  }, {
    path: "/platforms",
    component: _405c77e9,
    name: "platforms"
  }, {
    path: "/pools",
    component: _75a67ba0,
    children: [{
      path: "",
      component: _39606bba,
      name: "pools-index"
    }, {
      path: ":id",
      component: _d9000d6a,
      children: [{
        path: "",
        component: _3b211b8e,
        name: "pools-index-id"
      }, {
        path: "export",
        component: _0b91f7a8,
        name: "pools-index-id-export"
      }, {
        path: "update",
        component: _2512eb86,
        name: "pools-index-id-update"
      }]
    }]
  }, {
    path: "/socialmedia",
    component: _6014c9e0,
    name: "socialmedia"
  }, {
    path: "/stats",
    component: _3ae3f8b6,
    children: [{
      path: "",
      component: _1ef07a68,
      name: "stats"
    }, {
      path: "content",
      component: _072b59e2,
      name: "stats-content"
    }, {
      path: "highlights",
      component: _14e107ce,
      name: "stats-highlights"
    }, {
      path: "integrations",
      component: _de2ac30e,
      name: "stats-integrations"
    }]
  }, {
    path: "/support",
    component: _1bff5618,
    name: "support"
  }, {
    path: "/switch",
    component: _06e291a1,
    name: "switch"
  }, {
    path: "/terms",
    component: _500e56e6,
    name: "terms"
  }, {
    path: "/auth/2fa",
    component: _776c6720,
    name: "auth-2fa"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _9a19d152,
    name: "auth-logout"
  }, {
    path: "/auth/register",
    component: _07668533,
    name: "auth-register"
  }, {
    path: "/auth/restore",
    component: _5a9ff091,
    name: "auth-restore"
  }, {
    path: "/content/import",
    component: _b9aaaa36,
    name: "content-import"
  }, {
    path: "/oauth/webflow",
    component: _a9d1eae0,
    name: "oauth-webflow"
  }, {
    path: "/organisation/activity",
    component: _f35c7dac,
    name: "organisation-activity"
  }, {
    path: "/organisation/admin",
    component: _acdc5b78,
    children: [{
      path: "",
      component: _766095c7,
      name: "organisation-admin"
    }, {
      path: "data",
      component: _8b782b76,
      name: "organisation-admin-data"
    }, {
      path: "languages",
      component: _67278210,
      name: "organisation-admin-languages"
    }, {
      path: "notificationfilters",
      component: _66e5f3e5,
      name: "organisation-admin-notificationfilters"
    }, {
      path: "security",
      component: _5dcd054a,
      name: "organisation-admin-security"
    }]
  }, {
    path: "/organisation/cleanup",
    component: _1ffc204e,
    children: [{
      path: "",
      component: _07876f9c,
      name: "organisation-cleanup"
    }, {
      path: "pool",
      component: _2fd82142,
      name: "organisation-cleanup-pool"
    }, {
      path: "rows",
      component: _0da3e47f,
      name: "organisation-cleanup-rows"
    }, {
      path: "trash",
      component: _7e4c9702,
      name: "organisation-cleanup-trash"
    }]
  }, {
    path: "/organisation/connections",
    component: _02ff9f8a,
    children: [{
      path: "",
      component: _1e74d68d,
      name: "organisation-connections"
    }, {
      path: "integrations",
      component: _76db8298,
      name: "organisation-connections-integrations"
    }, {
      path: "openai",
      component: _9b7ffc72,
      name: "organisation-connections-openai"
    }]
  }, {
    path: "/organisation/entities",
    component: _223a4342,
    name: "organisation-entities"
  }, {
    path: "/organisation/focusboards",
    component: _0404365d,
    name: "organisation-focusboards"
  }, {
    path: "/organisation/payment",
    component: _e6e76b8a,
    name: "organisation-payment"
  }, {
    path: "/organisation/roles",
    component: _04afa096,
    name: "organisation-roles"
  }, {
    path: "/organisation/users",
    component: _7121cd40,
    name: "organisation-users"
  }, {
    path: "/platforms/create",
    component: _1a326016,
    name: "platforms-create"
  }, {
    path: "/pool/create",
    component: _ddace256,
    name: "pool-create"
  }, {
    path: "/socialmedia/create",
    component: _6b8e8644,
    name: "socialmedia-create"
  }, {
    path: "/support/create",
    component: _e8c090b4,
    name: "support-create"
  }, {
    path: "/content/users/create",
    component: _8fe1f5ba,
    name: "content-users-create"
  }, {
    path: "/organisation/entities/create",
    component: _152d383f,
    name: "organisation-entities-create"
  }, {
    path: "/organisation/focusboards/create",
    component: _b7923ffe,
    name: "organisation-focusboards-create"
  }, {
    path: "/organisation/roles/create",
    component: _41dbdcae,
    name: "organisation-roles-create"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/content/schemas/:id?",
    component: _1b6a89e6,
    name: "content-schemas-id"
  }, {
    path: "/content/users/:id?",
    component: _530aea23,
    children: [{
      path: "",
      component: _24fa0a66,
      name: "content-users-id"
    }, {
      path: "focusboards",
      component: _6026d439,
      name: "content-users-id-focusboards"
    }, {
      path: "platforms",
      component: _75669a18,
      name: "content-users-id-platforms"
    }, {
      path: "pools",
      component: _276b7a2a,
      name: "content-users-id-pools"
    }, {
      path: "roles",
      component: _6a2e82d1,
      name: "content-users-id-roles"
    }, {
      path: "update",
      component: _3ebb7665,
      name: "content-users-id-update"
    }]
  }, {
    path: "/organisation/entities/:id",
    component: _97fa26f2,
    children: [{
      path: "",
      component: _6257b1ca,
      name: "organisation-entities-id"
    }, {
      path: "copilot",
      component: _28bda1c4,
      name: "organisation-entities-id-copilot"
    }, {
      path: "delete",
      component: _27e3c763,
      name: "organisation-entities-id-delete"
    }, {
      path: "fields",
      component: _3bb8ed1e,
      name: "organisation-entities-id-fields"
    }, {
      path: "form",
      component: _1060c1fc,
      name: "organisation-entities-id-form"
    }, {
      path: "interface",
      component: _40a4bab1,
      name: "organisation-entities-id-interface"
    }]
  }, {
    path: "/organisation/focusboards/:id",
    component: _38f62c05,
    name: "organisation-focusboards-id"
  }, {
    path: "/organisation/roles/:id",
    component: _49789b5d,
    children: [{
      path: "",
      component: _00c86820,
      name: "organisation-roles-id"
    }, {
      path: "permissions",
      component: _da952b5c,
      name: "organisation-roles-id-permissions"
    }, {
      path: "update",
      component: _468e602a,
      name: "organisation-roles-id-update"
    }, {
      path: "users",
      component: _46324256,
      name: "organisation-roles-id-users"
    }]
  }, {
    path: "/schemas/rows/:id?",
    component: _2d56f72a,
    children: [{
      path: "",
      component: _fe2e62a4,
      name: "schemas-rows-id"
    }, {
      path: "activity",
      component: _b74f793a,
      name: "schemas-rows-id-activity"
    }, {
      path: "connection",
      component: _08fdf0b2,
      name: "schemas-rows-id-connection"
    }, {
      path: "files",
      component: _63a105d3,
      name: "schemas-rows-id-files"
    }, {
      path: "permission",
      component: _67905723,
      name: "schemas-rows-id-permission"
    }, {
      path: "proposal",
      component: _324a11c6,
      name: "schemas-rows-id-proposal"
    }, {
      path: "update",
      component: _60a53b1d,
      name: "schemas-rows-id-update"
    }]
  }, {
    path: "/auth/register/:token/:id?",
    component: _2b296a14,
    name: "auth-register-token-id"
  }, {
    path: "/auth/reset/:token?/:id?",
    component: _ddac4c1c,
    name: "auth-reset-token-id"
  }, {
    path: "/filesmanager/:id",
    component: _6f5d1aeb,
    name: "filesmanager-id"
  }, {
    path: "/focusboards/:id?",
    component: _2e20a2d6,
    name: "focusboards-id"
  }, {
    path: "/platforms/:id",
    component: _3d496091,
    children: [{
      path: "",
      component: _7a151358,
      name: "platforms-id"
    }, {
      path: "pools",
      component: _eb74a24e,
      name: "platforms-id-pools"
    }, {
      path: "update",
      component: _602d8837,
      name: "platforms-id-update"
    }, {
      path: "integrations/:integration?",
      component: _8ceda3f2,
      children: [{
        path: "",
        component: _d8f2596c,
        name: "platforms-id-integrations-integration"
      }, {
        path: "delete",
        component: _1c98d5e3,
        name: "platforms-id-integrations-integration-delete"
      }, {
        path: "pools",
        component: _5ad70bcf,
        name: "platforms-id-integrations-integration-pools"
      }, {
        path: "tokens",
        component: _8af9735c,
        name: "platforms-id-integrations-integration-tokens"
      }, {
        path: "update",
        component: _bc706bfe,
        name: "platforms-id-integrations-integration-update"
      }]
    }]
  }, {
    path: "/schemas/:id?",
    component: _bc49aaba,
    name: "schemas-id"
  }, {
    path: "/support/:id",
    component: _431bfe80,
    name: "support-id"
  }, {
    path: "/switch/:id",
    component: _690aef6e,
    name: "switch-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
